import carrierLogosImg from "@/img/carrier_logos.svg";
import mainGraphicLogosImg from "@/img/hero_image_c.svg";
import logoPath from "@/img/logo.svg";
import "@/style.css";
import { initDictionary } from "@cospex/client/helpers/dictionary";
import ParcelConfigProvider from "@cospex/client/parcel/provider";
import GlobalProvider from "@cospex/client/provider";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import { Routes } from "@generouted/react-router/lazy";
import "intl-tel-input/build/css/intlTelInput.css";
import { createRoot } from "react-dom/client";

import theme from "./Theme";

const container = document.getElementById("app")!;

const languages = [
  { value: "en", label: "EN 🇬🇧" },
  { value: "fr", label: "FR 🇫🇷" },
  { value: "it", label: "IT 🇮🇹" },
];

initDictionary(languages);

createRoot(container).render(
  <GlobalProvider
    theme={theme}
    config={{
      logo: {
        src: logoPath,
      },
      languages,
    }}
  >
    <ParcelConfigProvider
      value={{
        carrierLogosImg,
        mainGraphicLogosImg,
        mainGraphicMessageOffset: "52%",
      }}
    >
      <Routes />
    </ParcelConfigProvider>
  </GlobalProvider>
);
